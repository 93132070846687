import { Dispatch } from "@reduxjs/toolkit";
import { AuthTypes } from "../../types/auth";

export const authAction =
    (data: any) =>
        (submitType: string) =>
            async (dispatch: Dispatch) => {
                try {
                    switch (submitType) {
                        case AuthTypes.LOGIN_SUCCESS:
                            localStorage.setItem('access_token', data?.token);
                            localStorage.setItem('refresh_token', data?.refreshToken);
                            dispatch({ type: AuthTypes.LOGIN_SUCCESS, payload: data });
                            break;
                        case AuthTypes.LOGIN_FAILURE:
                            dispatch({ type: AuthTypes.LOGIN_FAILURE, payload: data });
                            break;
                        case AuthTypes.SIGN_OUT_SUCCESS:
                            localStorage.removeItem('access_token');
                            localStorage.removeItem('refresh_token');
                            dispatch({ type: AuthTypes.SIGN_OUT_SUCCESS, payload: data });
                            break;
                        case AuthTypes.REGISTRATION_SUCCESS:
                            dispatch({ type: AuthTypes.REGISTRATION_SUCCESS, payload: data });
                            break;
                        case AuthTypes.REGISTRATION_FAILURE:
                            dispatch({ type: AuthTypes.REGISTRATION_FAILURE, payload: data });
                            break;
                        case AuthTypes.REGISTRATION_ACTIVATED:
                            localStorage.setItem('access_token', data?.response?.token);
                            localStorage.setItem('refresh_token', data?.response?.refreshToken);
                            dispatch({ type: AuthTypes.REGISTRATION_ACTIVATED, payload: data });
                            break;
                        case AuthTypes.REGISTRATION_ACTIVATED_FAILURE:
                            dispatch({ type: AuthTypes.REGISTRATION_ACTIVATED_FAILURE, payload: data });
                            break;
                        case AuthTypes.FORGET_PSW_SUCCESS:
                            dispatch({ type: AuthTypes.FORGET_PSW_SUCCESS, payload: data });
                            break;
                        case AuthTypes.FORGET_PSW_FAILURE:
                            dispatch({ type: AuthTypes.FORGET_PSW_FAILURE, payload: data });
                            break;
                        case AuthTypes.CHANGE_PSW_SUCCESS:
                            localStorage.setItem('access_token', data.token);
                            localStorage.setItem('refresh_token', data.refreshToken);
                            dispatch({ type: AuthTypes.CHANGE_PSW_SUCCESS, payload: data });
                            break;
                        case AuthTypes.REFRESH_TOKEN_SUCCESS:
                            dispatch({ type: AuthTypes.REFRESH_TOKEN_SUCCESS, payload: data });
                            break;
                        case AuthTypes.REFRESH_TOKEN_FAILURE:
                            dispatch({ type: AuthTypes.REFRESH_TOKEN_FAILURE, payload: data });
                            break;
                        // set new token to local storage
                        case AuthTypes.CONFIRMATION_REFRESH_TOKEN_SUCCESS:
                            localStorage.setItem('access_token', data.token);
                            localStorage.setItem('refresh_token', data.refreshToken);
                            break;
                        case AuthTypes.CONFIRMATION_REFRESH_TOKEN_FAILURE:
                            break;
                        case AuthTypes.REMOVE_ERRORS:
                            dispatch({ type: AuthTypes.REMOVE_ERRORS });
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.log("error", error);
                }
                return null;
            };
