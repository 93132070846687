import { FieldError, SetFieldValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppText } from "../../../components"

interface UserNameInputFieldProps {
    error: FieldError | undefined;
    className?: string;
    register?: any;
    setIsTyping: any;
    isTyping: boolean;
    setValue: SetFieldValue<any>;
}

const UserNameInputField = ({ error, setValue, className, register, isTyping, setIsTyping, ...rest }: UserNameInputFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const handleUserNameType = (value: any) => {
        if (value.length > 0) {
            const testRegex = /[A-Za-z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/;
            const isValid = testRegex?.test(value);
            return isValid ? setValue("is_email", true) : setValue("is_email", false);
        } else {
            setValue("is_email", 'initial');
        }
    }
    return (
        <div className={className} >
            <input
                name="username"
                type="text"
                className={`p-4 text-base ${error ? "mb-1" : "mb-4"} w-full h-12 outline-none ${error ? 'input-red-border' : 'input-gray-border'} font-normal  not-italic leading-full`}
                placeholder={t("email_phone")}
                {...register("username", {
                    onChange: (event: any) => {
                        isTyping === false && setIsTyping(true);
                        handleUserNameType(event?.target?.value)
                    },
                })}
                {...rest}
            />
            {error && error?.message === "email" &&
                <AppText text={t("msg_email")} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" />
            }
            {
                error && error?.message === "phone" &&
                <AppText text={t("msg_phone")} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" />
            }
            {
                error && error?.message === 'initial' &&
                <AppText text={t("msg_email_phone")} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" />
            }
        </div >
    )
}
export default UserNameInputField