import axios from "axios";
import { FieldError, SetFieldValue, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { baseURL } from "../../../api";
import IEditUserInfo from "../../../_typescript/IEditUserInfo";
import { AppText } from "../../general";

type FormAsyncSelectProps = {
    placeHolder: string;
    select_options: Array<any>;
    name: string;
    error?: FieldError | undefined;
    register: UseFormRegister<IEditUserInfo>;
    setValue: SetFieldValue<any>;
    setIsTyping?: void | any;
    isTyping?: boolean;
    label?: string;
    getValues?: any;
    value?: string;
    selectedValue?: { value: string, zip: string };
    setSelectedValue?: any;
}

const FormAsyncSelect = ({ placeHolder, selectedValue, setSelectedValue, getValues, select_options, name, error, register, setValue, value, setIsTyping, isTyping, label, ...rest }: FormAsyncSelectProps) => {

    const { t } = useTranslation();

    const customStyles = {
        control: (styles: any) => ({
            ...styles,
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            height: "3rem"
        }),

        noOptionsMessage: (styles: any) => ({
            ...styles,
            fontSize: "0.875rem",
        }),

        placeholder: (styles: any) => ({
            ...styles,
            fontSize: "0.875rem",
            fontWeight: 500,
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
    };

    const handleChange = (value: any, name: string) => {
        if (name === 'city') {
            setValue('city', value.cities.name);
            setSelectedValue({ value: value.cities.name, label: value.cities.name, zip: value.zip });
        } else {
            setValue('city', value.cities[0].name);
            setSelectedValue({ value: value.cities[0].name, label: value.cities[0].name, zip: value.zip });
        }
        setValue('zipCode', value.zip);
    }

    const fetchCity = async (
        value: string,
        callBack: (args: any) => void
    ) => {
        if (value.length >= 4) {
            const res = await axios.get(
                `${baseURL}/localities?city=${value}`
            );

            const data = res.data;

            const cities: Array<any> = data;

            const cities_data = cities.map((city) => ({
                label: city.cities.name,
                value: city.cities.name,
                ...city
            }));
            callBack(cities_data);

        } else {
            callBack([{ label: '', value: '' }]);
        }
    };
    const fetchZipCode = async (
        value: string,
        callBack: (args: any) => void
    ) => {
        if (value.length >= 3) {
            const res = await axios.get(
                `${baseURL}/localities?zipCode=${value}`
            );

            const data = res.data;
            const zipCodes: Array<any> = data;

            const zipCodes_data = zipCodes.map((city) => ({
                label: city.zip,
                value: city.zip,
                ...city
            }));
            callBack(zipCodes_data);

        } else {
            callBack([{ label: '', value: '' }]);
        }
    };

    return (
        <>
            {label && <AppText className="pb-4" text={t(`${label}`)} />}
            <div className="relative">
                <AsyncSelect
                    styles={customStyles}
                    placeholder={placeHolder}
                    noOptionsMessage={() => "Aucun infos trouvé"}
                    loadingMessage={() => "Chargement..."}
                    // isClearable
                    cacheOptions
                    defaultOptions
                    value={name === 'city' ? { value: selectedValue?.value, label: selectedValue?.value } : { value: selectedValue?.zip, label: selectedValue?.zip }}
                    loadOptions={name === "city" ? fetchCity : fetchZipCode}
                    onChange={(event) => {
                        handleChange(event, name)
                        isTyping === false && setIsTyping(true)
                    }}
                    {...rest}
                />
                <span className=' absolute top-6 right-4 dropdown_icon'></span>

            </div>
        </>
    )
}
export default FormAsyncSelect