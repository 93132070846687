import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { AppButton, AppImage, AppText } from "../../../components"
import DownloadIcon from '../../../assets/icons/Download.svg'
import CalenderIcon from '../../../assets/icons/Calendar.svg'
import UserIcon from '../../../assets/icons/UserCircle.svg'
import IdNumberIcon from '../../../assets/icons/IdNumber.svg'
import NoteIcon from '../../../assets/icons/Note.svg'
import './index.css'
import { getInsurancesById } from "../../../api/insuranceApi"
import api, { baseURL } from "../../../api"
import { useSelector } from "react-redux"
import IUserReducerState from "../../../_typescript/IUserReducerState"
import dayjs from "dayjs"
import { IInsuranceDetail, IInsuranceDetailRow, InsuranceDetailProps, IParams } from "../../../_typescript/IStatusInsurance"

function InsuranceDetail({ className }: InsuranceDetailProps) {
    const history = useHistory()
    const { t } = useTranslation()
    const { firstName, lastName } = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer.user)
    const [details, setDetails] = useState<IInsuranceDetail>()
    const [detailsRows, setDetailsRows] = useState<Array<IInsuranceDetailRow>>([])

    const userName = firstName + ' ' + lastName

    const params: IParams = useParams()
    const { id } = params

    useEffect(() => {
        getInsurancesById(id).then(res => {
            if (res?.status === 200) {
                const { data } = res
                const { type, expirationDate, id } = data
                setDetails(data);
                setDetailsRows([
                    {
                        inusranceId: 1,
                        src: NoteIcon,
                        key: t("Type"),
                        value: type?.name,
                    },
                    {
                        inusranceId: 2,
                        src: CalenderIcon,
                        key: t("Exp_Date"),
                        value: dayjs(expirationDate).format('DD-MM-YYYY'),
                    },
                    {
                        inusranceId: 3,
                        src: UserIcon,
                        key: t("Name"),
                        value: userName,
                    },
                    {
                        inusranceId: 4,
                        src: IdNumberIcon,
                        key: t("id_number"),
                        value: "#" + id,
                    }
                ])
            }
            else {
                history.push('/insurances')
            }
        })
    }, [id, userName])

    const OpenPdfFile = (URL: string) => {
        api.get(URL, {
            responseType: "arraybuffer" // important
        })
            .then(response => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(file);
                // Open new windows and show PDF
                window.open(fileURL);
            });
    }

    return (details && userName) ? (
        <div className={className}>
            <div className="insurance_detail-header flex flex-col gap-2 pb-4 border-b border-gray-border">
                <div className="flex">
                    <AppText className="text-2xl mobile:text-lg leading-full" text={`${t('Insurance')} #${id}`} />
                    <AppText className={`status-${details?.status?.id} text-sm leading-4 mobile:text-xs`} text={`${details?.status?.label}`} />
                </div>
                <AppText className="text-sm leading-4 mobile:text-xs" text={`${t('Added')} ${dayjs(details?.createdAt).format('DD-MM-YYYY')}`} />
            </div>
            <div className="insurance_detail-body flex gap-10 mt-8 mobile:flex-col">
                <div className="flex items-start w-40 h-40">
                    <AppImage className="w-full h-full object-contain px-2  border border-gray-border" src={baseURL + details?.provider?.logo} alt="" />
                </div>
                <div className="insurance_main-details  w-full mt-2">
                    <div className="flex flex-col mobile:flex-row mobile:items-center gap-2 mb-14 mobile:mb-4">
                        <label className="text-slight-black opacity-50 capitalize ">{t("Name")} :</label>
                        <AppText className="text-2xl leading-full " styleText={{ marginLeft: '-2px' }} text={details?.provider?.name} />
                    </div>
                    <div className="flex flex-col gap-6 mb-14">
                        {detailsRows.map(({ src, inusranceId, key, value }) => (
                            <div key={inusranceId} className="insurance_detail-row flex justify-start items-center gap-2 mobile:text-xs">
                                <div className=" flex justify-center  items-center w-8 h-8 bg-slight-beige rounded-4">
                                    <AppImage className="w-3 h-3" src={src} alt="insurance_logo" />
                                </div>
                                <label className="text-slight-black opacity-50 capitalize">{t(`${key}`)} :</label>
                                <AppText
                                    styleText={{ marginLeft: '-2px' }}
                                    text={value} />
                            </div>
                        ))}
                    </div>
                    {details.policefile?.fileName && <AppButton
                        className="insurance_detail-btn bg-note-gray rounded-4 h-12 min-w-sm max-w-sm w-full  mobile:mb-8 mobile:text-xs"
                        withIcon
                        iconSrc={DownloadIcon}
                        textStyle="-pl-4"
                        text={t('view_insurance')}
                        onClick={() => {
                            OpenPdfFile(`${baseURL}${details.policefile?.fileName}`);
                        }}
                    />}
                </div>
            </div>

        </div>
    ) : null
}

export default InsuranceDetail