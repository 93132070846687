import IRoute from "../_typescript/IRoute"
import Home from "../pages/home/index"
import Profil from "../pages/profil/index"
import Insurance from "../pages/insurance/index"
export const routes: IRoute[] = [
    {
        path: '/',
        name: 'Home Page',
        component: Home,
        exact: true
    },
    {
        path: '/insurances',
        name: 'Insurance Page',
        component: Insurance,
        exact: false
    },
    {
        path: '/profil',
        name: 'Profile Page',
        component: Profil,
        exact: false
    },
    {
        path: '/group',
        name: 'group Page',
        component: () => <h1 className='flex items-center justify-center'>Group</h1>,
        exact: true
    },
    {
        path: '/safe',
        name: 'safe Page',
        component: () => <h1 className='flex items-center justify-center'>Safe</h1>,
        exact: true
    }
]