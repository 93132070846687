import CardHeader from "./cardHeader/index";

type CardProps = {
    className?: string;
    title: string;
    logo: any;
    children?: any;
    onClick?: () => void;
};
const Card = ({ className, onClick, title, logo, children }: CardProps): JSX.Element => {
    return (
        <div className={className}>
            <CardHeader onClick={onClick} className="flex text-blue-link max-w-lg w-full items-center justify-between pr-4 pl-4 pt-2 pb-2 bg-light-blue mobile:p-2" title={title} logo={logo} />
            {children}
        </div>
    )
}
export default Card
