import { useState } from "react"
import NavbarLinks from "../sidebarLinks/index"
import {
    AppLogoIcon,
    AppLogoIconMobile,
    DashboardIcon,
    InsuranceIcon,
    GroupIcon,
    ProfilIcon,
    SafeLockIcon,
    BrokerIcon,
    WarningIcon,
    HelpIcon
} from '../../assets/icons/navIcons/index'
import { AppImage } from '../../components/general'
import './index.css'
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'
export default function Navbar(): JSX.Element {
    const { t } = useTranslation()
    const history = useHistory();
    const location = history.location.pathname;
    const navigationLinks: any = [
        { link: '/', Logo: <DashboardIcon />, text: t("Dashboard") },
        { link: '/insurances', Logo: <InsuranceIcon />, text: t("Insurance") },
        { link: '/profil/personal-infos', Logo: <ProfilIcon />, text: t("Profil") },
        { link: '/group', Logo: <GroupIcon />, text: t("Your group") },
        { link: '/safe', Logo: <SafeLockIcon />, text: t("Safe") },
    ]
    const [isActiveLink, setActiveLink] = useState(true)
    return (
        <nav className="navbar-container bg-light-gray flex flex-col min-h-screen border-r border-gray-border tablet:min-w-215">
            <div className=" flex justify-start navbar-container_header py-4 px-6 border-b border-gray-border max-h-65 h-full mobile:p-2">
                <div className='app_logo mobile:hidden'>
                    <AppImage src={AppLogoIcon} alt="app-logo" />
                </div>
                <div className='app_logo_mobile flex items-center justify-center md:hidden'>
                    <AppImage src={AppLogoIconMobile} alt="app-logo" />
                </div>
            </div>
            <div className="flex flex-col h-full justify-between  mobile:m-0 mr-4 ml-3 mt-3">
                <div className="navbar-container_menu">
                    {navigationLinks?.map((navigation: any, index: string) =>
                        <NavbarLinks
                            key={index}
                            onClick={() => setActiveLink(!isActiveLink)}
                            link={navigation.link}
                            text={navigation.text}
                            textStyle="font-NeueHaasUnica not-italic font-normal ml-3"
                            className={`${location === navigation.link ? "activeLink" : ""} flex items-center tablet:pr-0 tablet:pl-0 px-4 py-3  mobile:p-2  mobile:justify-center `}
                            Logo={navigation.Logo} />
                    )}

                </div>
                <div className="navbar-container_footer mb-6">
                    <NavbarLinks link="/broker_contact" textStyle="font-NeueHaasUnica not-italic font-normal ml-3 min-w-165" text={t("My_Broker_Contacts")} className={`flex mobile:justify-center px-4 py-3 mobile:p-2 tablet:pl-0 tablet:pr-0 items-center border-b border-gray-border`} Logo={<BrokerIcon className="" />} />
                    <NavbarLinks link="/sinister_declaration" textStyle="font-NeueHaasUnica not-italic font-normal ml-3 min-w-165" text={t("Declare_a_sinister")} className={`flex mobile:justify-center pt-3 pb-3 pr-4 pl-4  tablet:pl-0 tablet:pr-0 items-center border-b border-gray-border`} Logo={<WarningIcon className="" />} />
                    <NavbarLinks link="/help" textStyle="font-NeueHaasUnica not-italic font-normal ml-3 min-w-165" text={t("Help_Support")} className={`flex mobile:justify-center pt-3 pb-3 pr-4 pl-4   tablet:pl-0 tablet:pr-0 items-center `} Logo={<HelpIcon className="" />} />
                </div>
            </div>
        </nav>
    );
}
