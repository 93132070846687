import { AppImage, AppLogoutButton, AppText, FormInputField } from "../../components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../api";
import LoopIcon from "../../assets/icons/navIcons/LoopIcon";
import ArrowRight from "../../assets/icons/ArrowRight.svg";
import { useTranslation } from 'react-i18next';
import IUserReducerState from "../../_typescript/IUserReducerState";
import NavbarLinks from "../sidebarLinks";
import { DashboardIcon, InsuranceIcon } from "../../assets/icons/navIcons";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useHistory, useLocation } from "react-router-dom";

import './index.css'

const NavBar = (): JSX.Element => {
    const history = useHistory();
    const currentLocation = useLocation()
    const [location, setLocation] = useState<string>("")
    const BACK_TO_INSURANCE = "BACK_TO_INSURANCE"
    const BACK_TO_GROUP = "BACK_TO_GROUP"
    const { t } = useTranslation();
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer.user);
    const navigationLinks: any = [
        { link: '/profil/personal-infos', Logo: <DashboardIcon />, text: t("Profil_Settings") },
        { link: '/insurances', Logo: <InsuranceIcon />, text: t("Insurance") },
    ]
    const { lastName, firstName, image } = UserInfos
    const handleToggle = (e: any) => {
        e.preventDefault();
        setToggleDropdown((prevState) => !prevState);
    };
    const closeToggle = () => {
        setToggleDropdown(false);
    };
    const ref = useDetectClickOutside({ onTriggered: closeToggle });
    useEffect(() => {
        const checkPath = currentLocation.pathname.includes('/insurances/') ? BACK_TO_INSURANCE : currentLocation.pathname.includes('/group/') ? BACK_TO_GROUP : ""

        setLocation(checkPath);
    }, [currentLocation])

    return (
        <div ref={ref} className="relative flex max-h-65 h-full bg-white border-b border-gray-border ">
            <div className="search_input flex items-center border-r flex-grow border-gray-border">
                {location.length > 0 &&
                    <div
                        onClick={() => {
                            console.log("location", location)
                            location === BACK_TO_INSURANCE ? history.push("/insurances") : location === BACK_TO_GROUP ? history.push("/group") : history.push("/")
                        }}
                        className={`flex items-center justify-center cursor-pointer border-r h-full ${location === BACK_TO_INSURANCE ? "  w-1/3" : "w-1/5"} tablet:w-1/2  mobile:w-1/6`}>
                        <AppImage className='h-4 mt-0.5 mr-3 mobile:m-0' src={ArrowRight} alt='arrow' />
                        <AppText className="mobile:hidden" text={location === BACK_TO_INSURANCE ? t("back_to_all_list") : t("back")} />
                    </div>
                }

                <LoopIcon className="ml-8 mobile:ml-2" />
                <FormInputField type="search" className="text-sm text-slight-black outline-none w-full ml-4 h-full" placeholder={`${t("Search")}...`} />
            </div>
            <div
                onClick={handleToggle}
                className="navbar_profil flex items-center cursor-pointer pl-6 pr-6 tablet:pl-2 tablet:pr-0 mobile:pr-0 mobile:pl-0">
                {
                    image &&
                    <AppImage className="mr-2 h-8 w-8 object-contain rounded-navbar-avatar mobile:ml-2 mobile:mr-1" src={baseURL + image} alt="avatar" />
                }
                {
                    lastName && firstName &&
                    <AppText className="select-none overflow-hidden overflow-ellipsis whitespace-nowrap tablet:hidden mobile:hidden" text={firstName + " " + lastName} />
                }
                <span
                    className={`ml-2 mobile:ml-0 dropdown_icon`}
                ></span>
            </div>
            <div
                onClick={handleToggle}
                // onBlur={() => setDropdown(!dropdown)}
                className={`dropdown ${toggleDropdown ? "dropdown_content" : "hidden"} mobile:w-auto `}>
                <div className="dropdown_content_item">
                    {navigationLinks.map((navigation: any, index: number) => (
                        <NavbarLinks
                            type="navbar"
                            key={index}
                            onClick={() => handleToggle}
                            link={navigation.link}
                            text={navigation.text}
                            textStyle="font-NeueHaasUnica not-italic font-normal ml-3"
                            className={`${location === navigation.link ? "activeLink" : ""} flex items-center tablet:pr-0 tablet:pl-0 px-4 py-3  mobile:p-2  mobile:justify-start `}
                            Logo={navigation.Logo} />
                    ))}
                </div>
                <div className="dropdown_content_item">
                    <AppLogoutButton
                        btnStyle={{ marginTop: '0', padding: "0 1rem" }}
                        className="navbar-logout mobile:p-2.5 border-none"
                        text={t('logout')}
                    />
                </div>
            </div>
        </div >
    );
}

export default NavBar