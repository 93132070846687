import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { editPhoneNumberApi } from "../../../api/userApi";
import { AppLogoutButton, AppText } from "../../../components";
import { PhoneNumberValidators } from "../../../constants/form.validators";
import { FormFooter, PhoneInputField } from "../../../containers/form";
import Modal from "../../../containers/modal/index";
import { UserAction } from "../../../_redux/actions/user";
import { UserTypes } from "../../../_redux/types/user";
import IFormValidator from "../../../_typescript/IFormValidator";
import IUserReducerState from "../../../_typescript/IUserReducerState";
import Swal from 'sweetalert2'
import { appAction } from "../../../_redux/actions/app";
import { AppTypes } from "../../../_redux/types/app";


type EditPhoneNumberProps = {
    className?: string;
};

const EditPhoneNumber = ({ className }: EditPhoneNumberProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isTyping, setIsTyping] = useState(false);
    const [dataToSend, setDataToSend] = useState({});


    const [phoneIndicator, setPhoneIndicator] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<IFormValidator>({
        resolver: yupResolver(PhoneNumberValidators),
        defaultValues: {
            phone: "",
        }
    });


    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer.user);

    const { phoneNumber } = UserInfos;

    useEffect(() => {
        setValue('phone', phoneNumber)
    }, [phoneNumber])
    const handleFormSubmit = (data: any) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // create payload
        let new_phone = data.phone;
        data.phone[0] === "+" ? new_phone = data.phone : new_phone = phoneIndicator + data.phone;
        // call edit phone api
        setDataToSend(data)
        editPhoneNumberApi(new_phone).then((res) => {
            setIsTyping(false)

            if (res.status === 200) {
                UserAction(new_phone)(UserTypes.EDIT_PHONE_SUCCESS)(dispatch)
                setIsOpen(true)
            } else if (res.status === 404 && res.data.type === "OK") {
                Swal.fire({
                    icon: 'success',
                    text: res?.data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,

                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: res?.data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,

                })
            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)
        }
        ).catch(err => {
            const UserPayload = err?.response?.data?.message
            UserAction(UserPayload)(UserTypes.EDIT_PHONE_FAILURE)(dispatch)
        })
    }


    return (
        <>
            <form className={`${className} pt-8 mobile:pr-4 flex w-10/12 py-8 flex-col mobile:py-0 mobile:w-full`}>
                <div className="flex mobile:flex-col mobile:gap-0 mobile:py-4 gap-8">
                    <div className="w-full">
                        <AppText className="pb-4 capitalize" text={t('Phone_number')} />
                        <PhoneInputField
                            isTyping={isTyping}
                            setIsTyping={setIsTyping}
                            setPhoneIndicator={setPhoneIndicator}
                            value={phoneNumber}
                            className="relative"
                            register={register}
                            error={errors.phone}
                        />
                    </div>
                    <div className="w-full">
                    </div>
                </div>
            </form>
            <div className="  max-w-form-footer border-t border-gray-border w-full flex justify-between mobile:flex-col-reverse ">
                <AppLogoutButton className="mobile:mb-8" text={t('logout')} />
                <FormFooter
                    onClick={handleSubmit((data) => handleFormSubmit(data))}
                    button_style={`mb-4 ${!isTyping ? "opacity-20" : ""}`}
                    className="py-8 mobile:py-2"
                    button_text={t("save_changes")}
                    withArrow={false}
                />
            </div>
            <Modal
                value={getValues("phone")}
                type='phone'
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                resend={() => handleFormSubmit(dataToSend)}
            />
        </>
    )
}
export default EditPhoneNumber;