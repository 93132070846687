import { useState } from 'react'
import { AppImage, AppText } from '../../../components';
import PasswordEye from '../../../assets/icons/password_open.svg'
import { useTranslation } from 'react-i18next';
import { FieldError, SetFieldValue } from 'react-hook-form';
interface PasswordInputFieldProps {
    error: FieldError | undefined;
    className?: string;
    register?: any;
    setIsTyping?: any;
    isTyping?: boolean;
    value?: string;
    name?: string;
    setValue: SetFieldValue<any>;
    placeholder?: string;
}

const PasswordInputField = ({ error, placeholder, value = "", name, setValue, className, isTyping, setIsTyping, register, ...field }: PasswordInputFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const [showPsw, setPswVisibility] = useState(false)
    const [currentPsw, setCurrentPsw] = useState(value)
    // variables for handling errors
    const PswLength = currentPsw.length
    const PswContainNumber = /\d/.test(currentPsw)
    const PswContainUpperCase = /[A-Z]/.test(currentPsw)
    return (
        <div className={className}>
            <input
                name={name ? name : 'password'}
                type={!showPsw ? "password" : "text"}
                className={`p-4 text-base  ${error ? 'mb-1' : 'mb-4'}  w-full h-12 outline-none ${error ? 'input-red-border ' : 'input-gray-border'}  font-NeueHaasUnica not-italic font-normal leading-full`}
                placeholder={name ? t(placeholder!) : t("Password")}
                {...register(name ? name : "password", {
                    onChange: (event: any) => {
                        isTyping === false && setIsTyping(true);
                        setCurrentPsw(event.target.value)
                        setValue(name, event.target.value)
                    }
                })}
            />
            <AppImage
                className="absolute top-4 right-4 cursor-pointer w-4 h-4"
                src={PasswordEye}
                alt='password eye'
                handleClick={() => (setPswVisibility(!showPsw))}
            />
            {error &&
                <div className='flex flex-col mb-4'>
                    <AppText text={t("msg_psw_length")} className={`font-NeueHaasUnica not-italic font-normal ${PswLength >= 8 ? "hidden" : "text-red-error"} text-xs leading-4 mt-3 `} />
                    <AppText text={t("msg_psw_isNumber")} className={`font-NeueHaasUnica not-italic font-normal ${PswContainNumber ? "hidden" : "text-red-error"} text-xs leading-4 mt-3`} />
                    <AppText text={t("msg_psw_isUpper")} className={`font-NeueHaasUnica not-italic font-normal ${PswContainUpperCase ? "hidden" : "text-red-error"} text-xs leading-4 mt-3 `} />
                </div>
            }
        </div>
    )
}
export default PasswordInputField;