import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { FormFooter, TextInputField } from '../form'
import { AppInternLink, AppText } from '../../components'
import { FieldError, useForm, UseFormRegister } from 'react-hook-form'
import IFormValidator from '../../_typescript/IFormValidator'
import { yupResolver } from '@hookform/resolvers/yup'
import { PhoneConfirmationCodeValidators } from '../../constants/form.validators'
import { UserAction } from '../../_redux/actions/user'
import { UserTypes } from '../../_redux/types/user'
import { useDispatch } from 'react-redux'
import { checkEmailActivationCodeApi, checkPhoneActivationCodeApi, EmailConfirmationApi } from '../../api/userApi'
import Swal from 'sweetalert2'
import { authAction } from '../../_redux/actions/auth'
import { AuthTypes } from '../../_redux/types/auth'
import { HashEmail } from '../../utils/index'
import { AppTypes } from '../../_redux/types/app'
import { appAction } from '../../_redux/actions/app'

type ModalProps = {
    value: string | undefined;
    className?: string;
    isOpen?: boolean;
    setIsOpen?: any,
    register?: UseFormRegister<any>;
    error?: FieldError | undefined;
    type?: "email" | "phone" | "email_confirmation";
    resend?: () => void;
}
const Modal = ({ className, value = "", isOpen, setIsOpen, error, type, resend }: ModalProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)
    const [isTyping, setIsTyping] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IFormValidator>({
        resolver: yupResolver(PhoneConfirmationCodeValidators),
        defaultValues: {
            code: "",
        }
    });
    const hashed_email = HashEmail(value)
    const hashed_phone = value?.slice(value.length - 4, value.length)
    const handleSubmitForm = (data: any) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        if (type === 'phone') {
            const new_phone = data?.code;
            checkPhoneActivationCodeApi(new_phone).then((res) => {
                setValue('code', '')
                setIsOpen(false)
                if (res?.status === 200) {

                    authAction(res?.data)(AuthTypes.CONFIRMATION_REFRESH_TOKEN_SUCCESS)(dispatch)
                    Swal.fire({
                        icon: 'success',
                        text: res?.data?.detail,
                        showCancelButton: false,
                        showConfirmButton: false,

                    })

                } else {
                    UserAction(res?.data?.detail)(UserTypes.CONFIRM_PHONE_FAILURE)(dispatch)
                    authAction(res?.data?.detail)(AuthTypes.CONFIRMATION_REFRESH_TOKEN_FAILURE)(dispatch)

                    Swal.fire({
                        icon: 'error',
                        text: res?.data?.detail,
                        showCancelButton: false,
                        showConfirmButton: false,

                    })
                }
                // stop loading
                appAction(AppTypes.STOP_LOADING)(dispatch)

            }).catch(err => {
                const UserPayload = err?.response?.data?.message
                UserAction(UserPayload)(UserTypes.EDIT_PHONE_FAILURE)(dispatch)
            })
        }
        else if (type === "email") {
            const payload = { email: value!, code: data?.code }
            checkEmailActivationCodeApi(payload).then((res) => {
                setValue('code', '')
                setIsOpen(false)
                if (res?.status === 200) {
                    authAction(res?.data)(AuthTypes.CONFIRMATION_REFRESH_TOKEN_SUCCESS)(dispatch)

                    UserAction(value!)(UserTypes.CONFIRM_EMAIL_SUCCESS)(dispatch)
                    Swal.fire({
                        icon: 'success',
                        text: res?.data?.detail,
                        showCancelButton: false,
                        showConfirmButton: false,
                    })

                } else {
                    UserAction(res?.data?.detail)(UserTypes.CONFIRM_EMAIL_FAILURE)(dispatch)
                    authAction(res?.data?.detail)(AuthTypes.CONFIRMATION_REFRESH_TOKEN_FAILURE)(dispatch)
                    Swal.fire({
                        icon: 'error',
                        text: res?.data?.detail,
                        showCancelButton: false,
                        showConfirmButton: false,

                    })
                }
                // stop loading
                appAction(AppTypes.STOP_LOADING)(dispatch)

            }).catch(err => {
                const UserPayload = err?.response?.data?.message
                UserAction(UserPayload)(UserTypes.EDIT_PHONE_FAILURE)(dispatch)
            })
        }
        else {
            const payload = { email: value!, emailCode: data?.code }
            EmailConfirmationApi(payload).then((res) => {
                setValue('code', '')
                if (res?.status === 200) {
                    setIsOpen(false)
                    Swal.fire({
                        icon: 'success',
                        text: res?.data?.detail,
                        showCancelButton: false,
                        showConfirmButton: false,
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: res?.data?.detail,
                        showCancelButton: false,
                        showConfirmButton: false,
                    })
                }
                // stop loading
                appAction(AppTypes.STOP_LOADING)(dispatch)
            })
        }
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setIsOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <form className="form flex flex-col bg-white p-8 rounded-32xl">
                                        <div className='mb-4 flex justify-between items-center'>

                                            <Dialog.Title as="h3" className="text-2xl leading-full font-NeueHaasUnica not-italic font-normal text-slight-black">
                                                {t("Confirmation_code")}
                                            </Dialog.Title>
                                            <AppInternLink
                                                onClick={() => resend!()}
                                                text="Resend Code"
                                                className="text-blue-link outline-none font-NeueHaasUnica not-italic font-normal  underline text-sm leading-4 mobile:text-xs"
                                            />
                                        </div>
                                        {type === "email" && <AppText text={`${t('Code_email_msg')} ${hashed_email}.`} />}
                                        {type === "email_confirmation" &&
                                            <AppText
                                                error_text={`${t('Email_Verification-Warning')}`}
                                                text={`${t('verification_code_msg')}${hashed_email} . ${t('Email_Verification')}`}
                                            />
                                        }
                                        {type === "phone" && <AppText text={`${t('SMS_code_msg')} ${hashed_phone}.`} />}
                                        <div className="form_body my-4">
                                            <TextInputField
                                                name='code'
                                                isTyping={isTyping}
                                                setIsTyping={setIsTyping}
                                                className='relative '
                                                register={register}
                                                error={errors.code}
                                                setValue={setValue}
                                                placeholder={t("Confirmation_code")}
                                            />
                                            {errors.code && <AppText error_text={t('field_required')} />}
                                        </div>
                                        <FormFooter button_text={t("Confirm")}
                                            button_style='mb-4'
                                            withArrow={false}
                                            onClick={handleSubmit((data) => handleSubmitForm(data))}
                                        />
                                        {type === "phone" && <AppText
                                            className="text-sm text-gray-modal font-NeueHaasUnica not-italic font-normal"
                                            onClick={() => setIsOpen(false)}
                                            text={`Enter the confirmation code that we sent to ${value}. Don’t enter the right phone number?`}
                                        />}
                                        {type === "email" && <AppText
                                            className="text-sm text-gray-modal font-NeueHaasUnica not-italic font-normal"
                                            onClick={() => setIsOpen(false)}
                                            text={`Enter the confirmation code that we sent to ${value}. Don’t enter the right email ?`}
                                        />}
                                        {type === "email_confirmation" && <AppText
                                            className="text-sm text-gray-modal font-NeueHaasUnica not-italic font-normal"
                                            onClick={() => setIsOpen(false)}
                                            text={`Enter the confirmation code that we sent to ${value}. Don’t enter the right email ?`}
                                        />}
                                        <AppText onClick={() => setIsOpen(false)} className=" cursor-pointer hover:underline text-gray-modal-text font-NeueHaasUnica not-italic font-normal" text=" Change it." />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog >
        </Transition.Root >
    )
}
export default Modal