import { CSSProperties } from 'react'
import AppImage from '../AppImage/index'
import LogoutIcon from "../../../assets/icons/Logout.svg"
import { authAction } from '../../../_redux/actions/auth'
import { AuthTypes } from '../../../_redux/types/auth'
import { useDispatch } from 'react-redux'

interface AppLogoutButtonProps {
    className?: string;
    text: string;
    textStyle?: string;
    btnStyle?: CSSProperties;
}

export default function AppLogoutButton({ text, btnStyle, className, textStyle }: AppLogoutButtonProps): JSX.Element {
    const dispatch = useDispatch()

    return (
        <button
            className={className + " " + "flex flex-wrap justify-between mobile:justify-center items-center border border-red-500 text-base mt-8 mobile:mt-0 px-8 py-2 text-red-500 h-12 text-center btn-primary rounded font-NeueHaasUnica not-italic font-normal "}
            onClick={() => {
                authAction([])(AuthTypes.SIGN_OUT_SUCCESS)(dispatch)
            }}
            style={btnStyle}
        >
            <AppImage className="mr-4" src={LogoutIcon} alt="logout-icon" />
            <p className={textStyle} >{text}</p>
        </button >
    )
}
