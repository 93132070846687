import { AppText } from "../../../components";
import { useTranslation } from 'react-i18next';
import { FieldError } from "react-hook-form";

interface AuthCodeInputFieldProps {
    className?: string;
    error?: FieldError | undefined;
    register?: any;
    setIsTyping?: any;
    isTyping?: boolean;
}

export default function AuthCodeInputField({ register, setIsTyping, isTyping, className, error, ...field }: AuthCodeInputFieldProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <>
            <div className={className}>
                <input
                    name="code"
                    type="text"
                    className={`p-4 text-base w-full h-12 outline-none ${error ? 'input-red-border ' : 'input-gray-border'} font-normal  not-italic leading-full`}
                    placeholder={t("Authentication_code")}
                    {...register("code", {
                        onChange: () => isTyping === false && setIsTyping(true),
                    })}
                    {...field}
                />
                {error &&
                    <AppText
                        text={t("msg_required")}
                        className={`font-NeueHaasUnica not-italic font-normal ${error ? "text-red-error" : "hidden"} mt-4 text-xs leading-4`} />
                }
            </div>
        </>
    )
}
