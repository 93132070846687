const HashEmail = (email: string) => {
    const name = email.substring(0, email.lastIndexOf("@"));
    const domain = email.substring(email.lastIndexOf("@"));
    const number_of_starts = name.length - 1;
    const first_letter = name[0];
    const hashed_part = []

    for (let i = 0; i < number_of_starts; i++) {
        hashed_part.push('*')
    }
    hashed_part.unshift(first_letter)
    const hashed_name = hashed_part.join('')
    return hashed_name + domain
}
export {
    HashEmail
}