import { useState } from 'react'
import { FormInputField } from "../../../components/form";
import { AppText, AppImage } from '../../../components/general';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../../api/authApi'
import IAuthUser from "../../../_typescript/IAuthUser"
import { AuthTypes } from '../../../_redux/types/auth'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginValidators } from "../../../constants/form.validators"
import IFormValidator from '../../../_typescript/IFormValidator'
import { useTranslation } from 'react-i18next';
import {
    PasswordInputField,
    UserNameInputField,
    FormHeader,
    FormFooter
} from "../../../containers/form";
import './index.css'
import { useHistory } from 'react-router-dom';
import { authAction } from '../../../_redux/actions/auth';
import { IAppState } from '../../../_typescript/IAuthReducer';
import { AppLogoIcon } from '../../../assets/icons/navIcons';
import { appAction } from '../../../_redux/actions/app';
import { AppTypes } from '../../../_redux/types/app';
import Modal from '../../../containers/modal';
// FIXME: add email approval check + modal for activation mail
// FIXME: add code for phone number approval +41 or not
const Login = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isTyping, setIsTyping] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dataToSend, setDataToSend] = useState({})
    const errorLoginMessage = useSelector((state: { authReducer: IAppState }) => state?.authReducer?.errors?.login_msg);
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<IFormValidator>({
        resolver: yupResolver(LoginValidators),
        defaultValues: {
            is_email: 'initial',
        }
    });
    const dispatch = useDispatch()
    const handleFormSubmit = (data: any) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        //   sign in
        setIsTyping(false)
        authAction('')(AuthTypes.REMOVE_ERRORS)(dispatch)
        const { username, password } = data;

        const user: IAuthUser = {
            username,
            password
        }
        setDataToSend(user)
        signIn(user)
            .then((res: any) => {
                if (res?.status === 200) {
                    const payload = res?.data
                    authAction(payload)(AuthTypes.LOGIN_SUCCESS)(dispatch)
                    history.push('/')
                } else if (res?.status === 403 && res?.data?.isNotActivated === true) {
                    setIsOpen(true)
                }
                else {
                    const payload = res.data.message
                    authAction(payload)(AuthTypes.LOGIN_FAILURE)(dispatch)
                }
                // stop loading
                appAction(AppTypes.STOP_LOADING)(dispatch)
            })
            .catch((err) => {
                const payload = err?.response?.data?.message
                authAction(payload)(AuthTypes.LOGIN_FAILURE)(dispatch)
            })
    }
    return (<>
        <div className="page_container flex items-center justify-center h-full mobile:mr-auto mobile:ml-auto mobile:mt-4 mobile:mb-4 mobile:w-10/12">

            <form
                className="form flex flex-col bg-white p-8 rounded-32xl"
                onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            >
                <AppImage className='w-36 h-20 self-center mb-16 mt-2' src={AppLogoIcon} alt="app-logo" />

                <FormHeader
                    title_text={t('Sign_in')}
                    link_text={t("create_an_account")}
                    link_subtext={t("or")}
                    link_path="/registration" />

                <div className="form_body mt-32xl">
                    <UserNameInputField setValue={setValue} register={register} isTyping={isTyping} setIsTyping={setIsTyping} error={errors.username} />

                    {!isTyping && errorLoginMessage ? <AppText text={errorLoginMessage} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" /> : null}

                    <PasswordInputField isTyping={isTyping} setIsTyping={setIsTyping} register={register} className="relative" error={errors.password} setValue={setValue} />

                    <div className="form_input-container mb-32xl flex ">
                        <FormInputField
                            id="rememberMe"
                            type="checkbox"
                            className="mr-2"
                        />
                        <AppText text={t("Remember_me")} className="text-sm leading-4 text-slight-black font-NeueHaasUnica not-italic font-normal" />
                    </div>

                </div>
                <FormFooter
                    button_style='mb-32xl'
                    button_text={t("Sign_In")}
                    link_text={t("Forgot_your_password?")}
                    link_path="/forgot-password"
                    withArrow={true}
                />

            </form>
            <Modal
                value={getValues("username")}
                type="email_confirmation"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                resend={() => handleFormSubmit(dataToSend)}
            />
        </div></>
    )
}
export default Login;
