import { useTranslation } from "react-i18next";
import { AppButton, AppText } from "../../../components";

type CardHeaderProps = {
    className?: string;
    title: string;
    logo: string;
    onClick?: () => void;
};

const CardHeader = ({ className, onClick, title, logo }: CardHeaderProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div onClick={onClick} className={className}>
            <div className="Card_title flex items-center">
                {logo}
                <AppText text={title} className="text-base ml-2 text-slight-black" />
            </div>
            <AppButton text={t('Explore_More')} className="border rounded border-blue-link p-2 pl-0 mobile:text-xs" />
        </div>
    );
}
export default CardHeader