interface AppTextProps {
    text?: string | any;
    className?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    id?: string;
    error_text?: string;
    withIcon?: boolean;
    icon?: JSX.Element;
    styleText?: { [key: string]: string };
    buttonStyle?: string
}

export default function AppText({ style, styleText, buttonStyle, icon, withIcon, text, error_text, id, className, onClick, ...rest }: AppTextProps): JSX.Element {
    return (
        <p id={id} onClick={onClick} {...rest} className={className + ' font-NeueHaasUnica not-italic font-normal'} style={style} >
            {error_text && <span className="text-red-500">{error_text}</span>}
            <span>
                {withIcon && icon}
                <span style={styleText} className={"ml-2"}>{text}</span>
            </span>
        </p>
    )
}
