import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppText, FormSelect } from '../../../components';
import SwissFlag from '../../../assets/icons/SwissFlag.svg';
import TunisianFlag from '../../../assets/icons/tunisian-flag.svg';
import { FieldError } from 'react-hook-form';
import './index.css';
const PhoneNumberOptions = [
    {
        label: (
            <div
                style={{
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    minWidth: "100px",
                    width: "100%",
                }}
            >
                <img src={SwissFlag} alt="swiss-flag-icon" />
                <p className="mr-2.5 ml-2.5 text-base leading-full text-slight-black" >+41</p>
            </div>
        ),
        value: "+41"
    },
    {
        label: (
            <div
                style={{
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    minWidth: "100px",
                    width: "100%",
                }}
            >
                <img src={TunisianFlag} alt="swiss-flag-icon" />
                <p className="mr-2.5 ml-2.5 text-base leading-full text-slight-black" >+216</p>
            </div>
        ),
        value: "+216"
    },

];
interface PhoneInputFieldProps {
    error: FieldError | undefined;
    className?: string;
    register?: any;
    setPhoneIndicator?: any;
    isTyping?: boolean;
    setIsTyping?: any;
    value?: any;
    selectStyle?: string;
    isDisabled?: boolean;
    setIsDisabled?: any;
}

const PhoneInputField = ({ error, selectStyle, isDisabled, setIsDisabled, value, register, isTyping, setIsTyping, setPhoneIndicator }: PhoneInputFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState("")
    const [indicatorIndex, setIndicatorIndex] = useState(0)
    useEffect(() => {
        if (value) {
            if (value[1] === "4") {
                setCurrentValue(value.slice(3,))
                setIndicatorIndex(0)
            } else {
                setCurrentValue(value.slice(4,))
                setIndicatorIndex(1)
            }
        }
    }, [value])
    return (
        <>
            <div className='phone_input flex'>
                <FormSelect
                    indicatorIndex={indicatorIndex}
                    setPhoneIndicator={setPhoneIndicator}
                    indicator={true}
                    className={`h-12 ${selectStyle}`}
                    select_options={PhoneNumberOptions}
                    isSearchable={false}
                // disabled
                />
                <div className="relative w-full">
                    <input
                        name='phone'
                        type="number"
                        className={`p-4 text-base ${error ? 'mb-1' : 'mb-4'}  w-full h-12 outline-none ${error ? 'input-red-border ' : 'input-gray-border'}  font-NeueHaasUnica not-italic font-normal leading-full`}
                        placeholder={t("Phone")}
                        value={currentValue}
                        {...register("phone", {
                            onChange: (event: any) => {
                                isTyping === false && setIsTyping(true)
                                isDisabled === true && setIsDisabled(false)
                                setCurrentValue(event.target.value)
                            },
                        })}
                    />
                </div>
            </div>
            {error && <AppText text={t("msg_phone")} className='font-NeueHaasUnica not-italic font-normal text-xs leading-4 text-red-error mt-1' />}
        </>
    )
}
export default PhoneInputField;