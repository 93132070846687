import { Dispatch } from '@reduxjs/toolkit';
import { UserTypes } from "../../types/user";

export const UserAction =
    (data: any) =>
        (submitType: string) =>
            async (dispatch: Dispatch) => {
                try {
                    switch (submitType) {
                        case UserTypes.GET_USER_SUCCESS:
                            dispatch({ type: UserTypes.GET_USER_SUCCESS, payload: data });
                            break;
                        case UserTypes.GET_USER_FAILURE:
                            dispatch({ type: UserTypes.GET_USER_FAILURE, payload: data });
                            break;
                        case UserTypes.EDIT_USER_SUCCESS:
                            dispatch({ type: UserTypes.EDIT_USER_SUCCESS, payload: data });
                            break;
                        case UserTypes.EDIT_USER_FAILURE:
                            dispatch({ type: UserTypes.EDIT_USER_FAILURE, payload: data });
                            break;
                        // edit profil picture
                        case UserTypes.EDIT_USER_IMAGE_SUCCESS:
                            dispatch({ type: UserTypes.EDIT_USER_IMAGE_SUCCESS, payload: data });
                            break;
                        case UserTypes.EDIT_USER_IMAGE_FAILURE:
                            dispatch({ type: UserTypes.EDIT_USER_IMAGE_FAILURE, payload: data });
                            break;
                        // edit email
                        case UserTypes.EDIT_EMAIL_SUCCESS:
                            dispatch({ type: UserTypes.EDIT_EMAIL_SUCCESS, payload: data });
                            break;
                        case UserTypes.EDIT_EMAIL_FAILURE:
                            dispatch({ type: UserTypes.EDIT_EMAIL_FAILURE, payload: data });
                            break;
                        case UserTypes.CONFIRM_EMAIL_SUCCESS:
                            dispatch({ type: UserTypes.CONFIRM_EMAIL_SUCCESS, payload: data });
                            break;
                        case UserTypes.CONFIRM_EMAIL_FAILURE:
                            dispatch({ type: UserTypes.CONFIRM_EMAIL_FAILURE, payload: data });
                            break;
                        // edit phone
                        case UserTypes.EDIT_PHONE_SUCCESS:
                            dispatch({ type: UserTypes.EDIT_PHONE_SUCCESS, payload: data });
                            break;
                        case UserTypes.EDIT_PHONE_FAILURE:
                            dispatch({ type: UserTypes.EDIT_PHONE_FAILURE, payload: data });
                            break;
                        case UserTypes.CONFIRM_PHONE_SUCCESS:
                            dispatch({ type: UserTypes.CONFIRM_PHONE_SUCCESS, payload: data });
                            break;
                        case UserTypes.CONFIRM_PHONE_FAILURE:
                            dispatch({ type: UserTypes.CONFIRM_PHONE_FAILURE, payload: data });
                            break;
                        // edit password
                        case UserTypes.EDIT_PASSWORD_SUCCESS:
                            dispatch({ type: UserTypes.EDIT_PASSWORD_SUCCESS, payload: data });
                            break;
                        case UserTypes.EDIT_PASSWORD_FAILURE:
                            dispatch({ type: UserTypes.EDIT_PASSWORD_FAILURE, payload: data });
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.log("error", error);
                }
                return null;
            };
