import group_banner from '../../../assets/images/Group_banner.jpg';
import { GroupIcon } from '../../../assets/icons/navIcons';
import avatar from "../../../assets/images/Avatar.jpg";
import avatar1 from '../../../assets/images/Avatar-1.jpg';
import avatar2 from '../../../assets/images/Avatar-2.jpg';
import avatar3 from '../../../assets/images/Avatar-3.jpg';
import { AppImage, AppText } from '../../../components';
import Card from '../../card';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type GroupCardProps = {
    className?: string;
};

const GroupCard = ({ className }: GroupCardProps): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();

    return <Card
        onClick={() => history.push('/group')}
        title={t("Your group")}
        logo={<GroupIcon color="#1356DC" />}
        className={className + " rounded border border-gray-border w-full max-w-card-width max-h-card-height"}
    >
        <div className="w-full h-full grid grid-cols-infos p-6 mobile:p-2">
            <div className="">
                <AppImage alt="group-avatar" className="" src={group_banner} />
            </div>
            <div className="group-body mobile:ml-2">
                <div className="group-body-header">
                    <AppText text="Family Group" className="text-base mobile:text-xs text-slight-black" />
                </div>
                <div className="">
                    <div className="group-body-pics flex mt-4">
                        <AppImage alt="profil-avatar" className="rounded-full w-8 h-8 relative" src={avatar} />
                        <AppImage alt="profil-avatar" className="rounded-full w-8 h-8 relative -left-3" src={avatar1} />
                        <AppImage alt="profil-avatar" className="rounded-full w-8 h-8 relative -left-6" src={avatar2} />
                        <AppImage alt="profil-avatar" className="rounded-full w-8 h-8 relative -left-9" src={avatar3} />
                    </div>
                </div>
                <div className="group-body-infos grid mt-5">
                    <div className="group_info_items flex flex-row gap-1">
                        <AppText text="Group members :" className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black opacity-50" />
                        <AppText text="4 peoples" className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black" />
                    </div>
                    <div className="group_info flex flex-row gap-1">
                        <AppText text="Invited members :" className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black opacity-50" />
                        <AppText text="1 peoples" className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black" />
                    </div>
                </div>
            </div>
        </div>
    </Card>;
}
export default GroupCard;