import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { addInsurances, getInsuranceProviders, getTypesByProvider } from '../../../../api/insuranceApi'
import { FormSimpleSelect } from '../../../../components'
import { AddInsuranceValidators } from '../../../../constants/form.validators'
import { DateInputField, FormFooter, TextInputField, UploadFileField } from '../../../../containers/form'
import { appAction } from '../../../../_redux/actions/app'
import { AppTypes } from '../../../../_redux/types/app'
import { IInsuranceModel, IInsuranceRequest } from '../../../../_typescript/IInsurancesModel'
import { IProviderModel } from '../../../../_typescript/IProviderModel'
import { ITypeInsuranceModel } from '../../../../_typescript/ITypeInsuranceModel'

type AddInsuranceProps = {
    className?: string
}

const AddInsurance = ({ className }: AddInsuranceProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isTyping, setIsTyping] = useState(false)
    const [providers, setProviders] = useState<Array<IProviderModel>>([])
    const [selectedProvider, setSelectedProvider] = useState(providers[0]);
    const [selectedType, setSelectedType] = useState();
    const [types, setTypes] = useState<Array<ITypeInsuranceModel>>([])
    const { register, reset, resetField, clearErrors, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm<IInsuranceModel>({
        resolver: yupResolver(AddInsuranceValidators),
        defaultValues: {
            provider: { value: '', label: '' },
            type: { value: undefined, label: "" },
            expirationDate: new Date().toISOString().split('T')[0],
            insuranceNumber: null,
            policeFile: { fileName: null },
        }
    });
    const idProvider = watch('provider')?.id

    !idProvider && setValue('type', { value: undefined, label: "" })

    const handleFormSubmit = async (data: IInsuranceModel) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // create payload

        const payload: IInsuranceRequest = {
            type: {
                id: data.type?.id
            },
            provider: {
                id: data.provider?.id
            },
            insuranceNumber: data?.insuranceNumber || null,
            expirationDate: data?.expirationDate,
            policefile: {
                fileName: data.policeFile!.fileName !== null ? data?.policeFile!.fileName : null,
            }
        }
        addInsurances(payload).then((res) => {
            console.log('res payload', res.status);
            if (res.status.id) {
                Swal.fire({
                    icon: 'success',
                    text: t("add_insurance_successfully"),
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                //    stop loading
                appAction(AppTypes.STOP_LOADING)(dispatch)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.detail ? res.data.detail : t("add_insurance_failed"),
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                //    stop loading
                appAction(AppTypes.STOP_LOADING)(dispatch)
            }

        })

    }

    useEffect(() => {
        getInsuranceProviders().then((data: Array<IProviderModel>) => {
            // add value and label for react select
            const providerToSet = data?.map((provider) => ({
                value: provider?.name,
                label: provider?.name,
                ...provider
            }) as IProviderModel)
            // set provider data
            setProviders(providerToSet)
        })
    }, [])
    useEffect(() => {
        setTypes([])
        if (idProvider) {
            getValues('provider')?.id && getTypesByProvider(idProvider, false).then((data: Array<ITypeInsuranceModel>) => {
                // add value and label for the select
                const typeToSet = data?.map((type) => ({
                    value: type?.name,
                    label: type?.name,
                    ...type
                }) as ITypeInsuranceModel)
                // set type data
                setTypes(typeToSet)
            })
        } else {
            setTypes([])
        }
    }, [getValues('provider')])

    return (<>
        <form
            className={`${className} mobile:pr-4 flex flex-col mobile:py-0 mobile:w-full gap-4`}
        >
            <div className="flex w-full mobile:flex-col mobile:gap-0 mobile:py-4  ">
                <div className="w-full flex  mobile:flex-col justify-between gap-8">
                    <div className='w-full'>
                        <FormSimpleSelect
                            placeholder={t('select_provider')}
                            label={t('Provider')}
                            labelStyle={"text-sm text-slight-black mb-4 -ml-2"}
                            selectOptions={providers}
                            name="provider"
                            className=""
                            isClearable
                            isSearchable
                            setIsTyping={setIsTyping}
                            isTyping={isTyping}
                            error={errors.provider}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            selectedProvider={selectedProvider}
                            setSelectedProvider={setSelectedProvider}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                        />
                        {idProvider && <FormSimpleSelect
                            placeholder={t('select_type')}
                            label={t('Type')}
                            labelStyle={"text-sm text-slight-black my-4 -ml-2"}
                            selectOptions={[...types]}
                            name="type"
                            className=""
                            isClearable
                            isSearchable
                            clearErrors={clearErrors}
                            setIsTyping={setIsTyping}
                            isTyping={isTyping}
                            error={errors.type}
                            setValue={setValue}
                            selectedProvider={selectedProvider}
                            setSelectedProvider={setSelectedProvider}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                        />}
                    </div>
                    <div className='w-full'>
                        <DateInputField
                            className='w-full'
                            name="expirationDate"
                            label={t('Exp_Date')}
                            labelStyle={"text-sm text-slight-black -ml-2"}
                            register={register}
                            setValue={setValue}
                            setIsTyping={setIsTyping}
                            isTyping={isTyping}
                            error={errors?.expirationDate}
                        />
                        <TextInputField
                            labelStyle={"text-sm text-slight-black -ml-2"}
                            error={errors.insuranceNumber}
                            name="insuranceNumber"
                            label='Insurance_number'
                            placeholder="Insurance_number"
                            register={register}
                            setValue={setValue}
                            setIsTyping={setIsTyping}
                            isTyping={isTyping}
                        />
                    </div>
                </div>

            </div>
            <div>
                <UploadFileField
                    name='policeFile'
                    setValue={setValue}
                    setIsTyping={setIsTyping}
                    isTyping={isTyping}
                />
            </div>
        </form >
        <div className="border-t border-gray-border    w-full flex justify-end mobile:flex-col-reverse ">
            <FormFooter
                onClick={handleSubmit((data) => handleFormSubmit(data))}
                disabled={!isTyping ? true : false}
                button_style={`mb-4 mt-4 ${!isTyping ? "opacity-20" : ""}`}
                className="py-8 mobile:pt-2 mobile:pb-4 mobile:pr-4"
                button_text={t("add_insurance")}
                withArrow={false}
            />
        </div>
    </>
    )
}

export default AddInsurance
