import ProfilNavbar from "./profil_navbar";
import ProfilRoutes from "./profil_routes";
import './index.css'
const Profil = (): JSX.Element => {
    return (
        <div className='profil_page bg-white mobile:max-w-85vw mobile:overflow-hidden'>
            <ProfilNavbar />
            <div className="relative min-h-78 flex w-full flex-col justify-between  ">
                <ProfilRoutes />
            </div>
        </div>
    )
}
export default Profil;
