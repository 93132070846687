import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from "../../../_redux/actions/auth"
import { AuthTypes } from '../../../_redux/types/auth'
import { PrivacyPolicy } from '../../../containers/index';
import { AppText, AppButton, AppImage } from '../../../components/general';
import { registrationApi } from '../../../api/authApi';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { SignUpValidators } from "../../../constants/form.validators"
import IFormValidator from '../../../_typescript/IFormValidator'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.css'
import {
    EmailInputField,
    FormFooter,
    FormHeader,
    PasswordInputField,
    PhoneInputField,
    PrivacyInputField
} from '../../../containers/form';
import i18n from '../../../translation/translate';
import { IAppState } from '../../../_typescript/IAuthReducer';
import { AppLogoIcon } from '../../../assets/icons/navIcons';
import { appAction } from '../../../_redux/actions/app';
import { AppTypes } from '../../../_redux/types/app';


const Registration = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory();

    const [isTyping, setIsTyping] = useState(false)
    const [phoneIndicator, setPhoneIndicator] = useState("+41")
    const [role, setRole] = useState<string>("ROLE_INDIVIDUAL_ACCOUNT");
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IFormValidator>({
        resolver: yupResolver(SignUpValidators),
        defaultValues: {
            password: "",
            email: "",
            phone: "",
            privacy_policy: "",
            code: "",
            username: "",
            is_email: "",
        }
    });

    const errorRegistrationMessage = useSelector((state: { authReducer: IAppState }) => state?.authReducer?.errors?.registration_msg);
    interface IFormValues {
        email?: string;
        password?: string;
        phone?: string;
    }
    const handleFormSubmit = (data: IFormValues) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // create payload

        const { email = "", password = "", phone = "" } = data;
        const phoneNumber = phoneIndicator + phone;
        const user = { email, password, phoneNumber, roles: role };
        const type = AuthTypes.REGISTRATION_SUCCESS;
        const payload = { email, phoneNumber, type }
        setIsTyping(false)
        authAction('')(AuthTypes.REMOVE_ERRORS)(dispatch)

        registrationApi(user).then((res) => {
            if (res?.status === 201) {
                authAction(payload)(AuthTypes.REGISTRATION_SUCCESS)(dispatch).then(() => {
                    history.push('/authentication-code')
                })
            } else {
                authAction(res?.data?.detail)(AuthTypes.REGISTRATION_FAILURE)(dispatch)
            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)
        })
            .catch(err => {
                console.log('err', err);
            })
    }
    const handleRoleChange = (currentValue: string, event: React.ChangeEvent<HTMLSelectElement>, setCurrentValue: (value: string) => void) => {
        return currentValue !== event.target.value ? setCurrentValue(event.target.value) : null
    }
    const handlePrivacyPolicyByLanguage = (language: string) => {
        return language === "en"
            ?
            "https://seenk-dev.ewm.dev/en/conditions.pdf"
            :
            "https://seenk-dev.ewm.dev/fr/conditions.pdf"
    }

    return (
        <div className="page_container flex items-center justify-center h-full mobile:mr-auto mobile:ml-auto mobile:mt-4 mobile:mb-4 mobile:w-10/12">
            <form
                className="form flex flex-col bg-white p-8 rounded-32xl"
                onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            >
                <AppImage className='w-36 h-20 self-center mb-16 mt-2' src={AppLogoIcon} alt="app-logo" />

                <FormHeader
                    title_text={t("create_an_account")}
                    link_path={"/sign-in"}
                    link_text={t('Sign_in')}
                    link_subtext={t("or")}
                />

                <div className="form_body mt-32xl">
                    <AppText
                        text={t("Account_type")}
                        className="text-base leading-full text-slight-black font-NeueHaasUnica not-italic font-normal mb-4"
                    />
                    <div className="mb-4 flex">
                        <AppButton
                            type="button"
                            value="ROLE_COMPANY_ACCOUNT"
                            text={t("Company_Account")}
                            // onClick={(event) => role !== event.target.value ? setRole(event.target.value) : null}
                            className="text-slight-black text-base leading-full p-4 mr-4 input-gray-border font-NeueHaasUnica not-italic font-normal mobile:text-sm "
                            disabled
                        />
                        <AppButton
                            type="button"
                            value="ROLE_INDIVIDUAL_ACCOUNT"
                            // onClick={(event) => handleRoleChange(role, event, setRole)}
                            text={t("Individual_Account")}
                            className="text-base text-blue-link leading-full input-blue-border p-4 font-NeueHaasUnica not-italic font-normal mobile:text-sm "
                        />
                    </div>
                    <div>
                        <EmailInputField setValue={setValue} isTyping={isTyping} setIsTyping={setIsTyping} register={register} error={errors.email} />
                        {!isTyping && errorRegistrationMessage ? <AppText text={errorRegistrationMessage} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" /> : null}

                        <PasswordInputField setValue={setValue} isTyping={isTyping} setIsTyping={setIsTyping} className='relative' register={register} error={errors.password} />

                        <div className="flex flex-col mb-4">
                            <PhoneInputField selectStyle='w-1/3 mr-4' isTyping={isTyping} setIsTyping={setIsTyping} setPhoneIndicator={setPhoneIndicator} register={register} error={errors.phone} />
                        </div>
                        <div className="form_input-container mb-32xl flex ">
                            <PrivacyInputField
                                className="mr-2"
                                error={errors.privacy_policy}
                                register={register}
                            />
                            <PrivacyPolicy
                                className="text-sm leading-4 font-NeueHaasUnica not-italic font-normal"
                                text={t("read_and_accepted")}
                                first_link_text={t("Terms_and_Conditions")}
                                first_link_url={handlePrivacyPolicyByLanguage(i18n.language)}
                                second_link_text={t("privacyPolicy")}
                                second_link_url={handlePrivacyPolicyByLanguage(i18n.language)}
                            />
                        </div>
                    </div>
                </div>
                <FormFooter
                    className="text-base text-white h-12 text-center btn-primary w-full bg-blue-button rounded"
                    button_style='mb-32xl'
                    button_text={t("create_an_account")}
                    withArrow={true}
                />
            </form>
        </div >
    )
}
export default Registration;
