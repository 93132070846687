import { useEffect, useState } from "react";
import { InsuranceIcon } from "../../../assets/icons/navIcons";
import { AppButton, AppText } from "../../../components";
import Card from "../../card";
import InsuranceStatus from "../../InsuranceStatus";
import { GetInsuranceNumbersByUser } from "../../../api/dashboardApi"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type InsurancesCardProps = {
    className?: string;
};
type InitialStateType = {
    imported: number
    toImport: number
    total: number
}
const InitialState: InitialStateType = {
    imported: 0,
    toImport: 0,
    total: 0
};
const InsurancesCard = ({ className }: InsurancesCardProps): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const [insuranceNumbers, setInsuranceNumbers] = useState(InitialState);
    useEffect(() => {
        GetInsuranceNumbersByUser().then((res) => setInsuranceNumbers(res?.data))
    }, [])
    return (
        <Card
            onClick={() => history.push('/insurances')}
            title={t("Insurances")}
            logo={<InsuranceIcon color="#1356DC" />}
            className={className + " rounded border border-gray-border w-full max-w-card-width max-h-card-height"}
        >
            <div className="Insurance_card-body p-6 mobile:p-2 tablet:gap-4 grid grid-cols-card">
                <div className="flex h-72 max-w-104 max-h-104 mobile:max-h-79 flex-col justify-center items-center border rounded bg-light-blue bg-opacity-50 ">
                    <AppText text={insuranceNumbers.total} className="text-26 text-slight-black opacity-20" />
                    <AppText text={t("Insurances")} className="text-base mobile:text-xs text-slight-black opacity-20" />
                </div>
                <div className="laptop:pl-2 mobile:pl-2 w-full">
                    <InsuranceStatus status="valide" text={`${t("Validated_Insurances")} : `} insuranceNumber={insuranceNumbers.imported} />
                    <InsuranceStatus status="pending" text={`${t("Pending_Insurances")} : `} insuranceNumber={insuranceNumbers.toImport} />
                </div>
            </div>
        </Card>
    );
}
export default InsurancesCard;
