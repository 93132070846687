import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import appReducer from "./appReducer";
import adressReducer from "./adressReducer";
import insuranceReducer from "./insuranceReducer";

const reducers = combineReducers({
    authReducer,
    userReducer,
    appReducer,
    adressReducer,
    insuranceReducer
})

export type RootState = ReturnType<typeof reducers>;
export default reducers;