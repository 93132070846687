import { ChangeEvent, useState } from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import api, { baseURL } from "../../../api";
import { AppText } from "../../../components"
import { UserAction } from "../../../_redux/actions/user";
import { UserTypes } from "../../../_redux/types/user";

interface FileInputFieldProps {
    error: FieldError | undefined;
    className?: string;
    register?: any;
    setIsTyping?: any;
    isTyping?: boolean;
    value?: string;
    src?: string;
    setAvatar?: any;
}

const FileInputField = ({ src, setAvatar, error, value, className, register, isTyping, setIsTyping, ...rest }: FileInputFieldProps): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState(value)

    const addDocumentFile = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        setAvatar(URL.createObjectURL(file))

        return await api
            .post(`${baseURL}/api/v0/add-attachment-file`, formData, {
                params: { 'attach-type': "userAvatar" },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                const status = res.status;
                const data = res.data.fileName;
                if (status === 201) {
                    UserAction(data)(UserTypes.EDIT_USER_IMAGE_SUCCESS)(dispatch)
                }
            })
            .catch(() => {
                throw new Error('Error in [uploadFiles]');
            });
    };
    return (
        <div className={className}>
            <input
                accept="image/* "
                id='img'
                name="file"
                type="file"
                className={`hidden`}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.target.files && setAvatar(URL.createObjectURL(event?.target?.files[0]))
                }}
                value={currentValue}
                {...register("file", {
                    onChange: (event: any) => {
                        isTyping === false && setIsTyping(true)
                        setCurrentValue(event.target.value)
                        addDocumentFile(event.target.files[0])
                    },
                })}
                {...rest}
            />
            <label className={"upload_button_style cursor-pointer text-blue-button border border-blue-button rounded px-2.5 py-2 text-sm leading-4 font-NeueHaasUnica not-italic font-normal"} htmlFor="img">{t("Upload_new_photo")}</label>
            <AppText className="pt-4 pb-0 text-xs leading-normal text-black opacity-30 font-NeueHaasUnica not-italic font-normal" text={t('Max size - 2 Mb JPG or PNG')} />
            <AppText className="text-xs leading-normal text-black font-NeueHaasUnica not-italic font-normal" text={t('Photos help your group mates recognize you in Seenk (Optional)')} />
            {/* {error && <AppText text={t("msg_email")} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" />} */}
        </div>
    )
}
export default FileInputField