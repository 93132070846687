import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SafeLockIcon } from "../../../assets/icons/navIcons";
import { AppText } from "../../../components";
import Card from "../../card";
import SafeDocument from "./SafeDocument";
type SafeCardProps = {
    className?: string;
};

const SafeCard = ({ className }: SafeCardProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    return <Card
        onClick={() => history.push('/safe')}
        title={t("Safe")}
        logo={<SafeLockIcon color="#1356DC" />}
        className={className + " rounded border border-gray-border max-w-card-width w-full max-h-card-height mobile:mb-12 tablet:mb-8"} >
        <div className="w-full h-full pt-1.5 pb-1.5 pl-4 pr-4 mobile:p-2 ">
            <div className="border-b border-gray-border pb-1.5">
                <AppText text=" last uploaded files" className="text-10 leading-4 uppercase text-black opacity-30  mobile:text-xs font-NeueHaasUnica not-italic font-normal" />
            </div>
            <SafeDocument className="border-b border-gray-border pb-1.5" text="Assurance - 2250_w3.pdf" type="pdf" date="29 June 2021 12:15" />
            <SafeDocument className="border-b border-gray-border pb-1.5" text="Assurance - 2250_w3.pdf" type="word" date="29 June 2021 12:15" />
            <SafeDocument text="Assurance - 2250_w3.pdf" type="pdf" date="29 June 2021 12:15" />
        </div>
    </Card>;
}
export default SafeCard;