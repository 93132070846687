import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppList, AppLogoutButton, AppText } from "../../../components";
import { ResetPasswordValidators } from "../../../constants/form.validators";
import { FormFooter, PasswordInputField } from "../../../containers/form";
import { UserAction } from "../../../_redux/actions/user";
import { UserTypes } from "../../../_redux/types/user";
import IPswFormValidator from "../../../_typescript/IPswFormValidator";
import Swal from 'sweetalert2'
import { changePasswordApi } from "../../../api/userApi";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { appAction } from "../../../_redux/actions/app";
import { AppTypes } from "../../../_redux/types/app";


type EditPasswordProps = {
    className?: string;
};

const password_notes = [
    { value: "Is longer than 7" },
    { value: "Does not match or significantly contain your username, e.g. do not use ‘username123’." },
    { value: "Is not a member of this list of common passwords" }
]

const EditPassword = ({ className }: EditPasswordProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [isTyping, setIsTyping] = useState(false)

    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<IPswFormValidator>({
        resolver: yupResolver(ResetPasswordValidators),
        defaultValues: {
            old_password: '',
            new_password: '',
            password_confirm: '',
        }
    });
    const handleFormSubmit = (data: { password: string, newPassword: string }) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // create payload
        const payload = {
            password: data.password,
            newPassword: data.newPassword,
        }
        // call change password api
        changePasswordApi(payload).then((res) => {
            if (res.status === 200) {
                UserAction(payload)(UserTypes.EDIT_PASSWORD_SUCCESS)(dispatch)
                setIsTyping(false)
                Swal.fire({
                    icon: 'success',
                    text: res?.data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                setValue('old_password', '',)
                setValue('new_password', '',)
                setValue('password_confirm', '',)
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: res?.data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,

                })
            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)

        }
        ).catch(err => {
            const UserPayload = err?.response?.data?.message
            UserAction(UserPayload)(UserTypes.EDIT_PASSWORD_FAILURE)(dispatch)
        })
    }

    return (
        <>
            <div className={`${className} pt-8 mobile:pr-4`}>
                <div className="edit-password-header">
                    <AppText text={t('Edit_password_note')} />
                    <AppList list_options={password_notes} className="list-disc ml-6" list_item_style="" />
                </div>
                <form className="flex w-10/12 py-8 flex-col mobile:py-0 mobile:w-full">
                    <div className="flex mobile:flex-col mobile:gap-0 gap-8  justify-between">
                        <div className="w-full">
                            <AppText className="pb-4" text={t('old_password')} />
                            <PasswordInputField
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                                setValue={setValue}
                                name='old_password'
                                placeholder="old_password"
                                className="relative"
                                register={register}
                                error={errors.old_password}
                            />
                        </div>
                        <div className="w-full">
                            <AppText className="pb-4" text={t('new_password')} />
                            <PasswordInputField
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                                setValue={setValue}
                                name="new_password"
                                placeholder="new_password"
                                className="relative"
                                register={register}
                                error={errors.new_password}
                            />
                        </div>
                    </div>

                    <div className="flex mobile:flex-col mobile:gap-0 gap-8">
                        <div className="w-full">
                            <AppText className="pb-4" text={t('verify_password')} />
                            <PasswordInputField
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                                setValue={setValue}
                                className="relative"
                                name='password_confirm'
                                placeholder={t('verify_password')}
                                register={register}
                                error={errors.password_confirm} />
                        </div>
                        <div className="w-full">
                        </div>
                    </div>

                </form>
            </div>
            <div className=" max-w-form-footer border-t border-gray-border  w-full flex justify-between mobile:flex-col-reverse ">
                <AppLogoutButton className="mobile:mb-8" text={t('logout')} />
                <FormFooter
                    onClick={handleSubmit((data) => handleFormSubmit({ password: data.old_password, newPassword: data.new_password }))}
                    button_style={`mb-4 ${!isTyping ? "opacity-20" : ""}`}
                    className="py-8 mobile:py-2"
                    button_text={t("save_changes")}
                    withArrow={false}
                />
            </div>
        </>
    );
}
export default EditPassword;