import InsurancesRoutes from "./insurance_routes";
import './index.css'
const Insurances = (): JSX.Element => {
    return (
        <div className='profil_page bg-white mobile:max-w-85vw mobile:overflow-hidden'>
            <InsurancesRoutes />
        </div>
    )
}
export default Insurances;
