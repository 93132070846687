import { FieldError, SetFieldValue, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppText } from "../../../components"
import IEditUserInfo from "../../../_typescript/IEditUserInfo";
import IFormValidator from "../../../_typescript/IFormValidator";

interface FileInputFieldProps {
    error: FieldError | undefined;
    className?: string;
    setIsTyping: any;
    isTyping: boolean;
    setValue: SetFieldValue<any>;
    name: string;
    label?: string;
    type?: string;
    disabled?: boolean;
    register: UseFormRegister<IEditUserInfo> | UseFormRegister<IFormValidator> | any;
    id?: string;
    placeholder?: string;
    labelStyle?: string;
}

const TextInputField = ({ error, id, name, label, labelStyle, placeholder, setValue, className, register, isTyping, type, setIsTyping, disabled, ...rest }: FileInputFieldProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            {label && <AppText className={`pb-4 ${labelStyle}`} text={t(`${label}`)} />}
            <input
                className={type !== 'search' ? `p-4 text-base ${error ? "mb-1 input-red-border" : "mb-4 input-gray-border"} w-full h-12 outline-none  font-normal  not-italic leading-full ${className}` : className}
                id={id}
                type="text"
                disabled={disabled}
                placeholder={placeholder ? t(`${placeholder}`) : ""}
                {...register(name, {
                    onChange: (event: { target: { value: string; } }) => {
                        isTyping === false && setIsTyping(true)
                        setValue(name, event.target.value)
                    },
                })}
                {...rest}
            />

            {/* {error && <AppText text={t("msg_email")} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" />} */}
        </div>
    )
}
export default TextInputField