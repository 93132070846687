import { Link } from 'react-router-dom'
import { AppText } from '../../components/general'
interface NavbarLinksProps {
    link: string;
    text: string;
    className?: string;
    Logo?: JSX.Element;
    textStyle?: string;
    logoStyle?: string;
    onClick?: () => void;
    type?: string;
}

export default function NavbarLinks({ link, type, text, Logo, className, textStyle, onClick }: NavbarLinksProps): JSX.Element {
    return (
        <div onClick={onClick} className={className}>
            <Link className='flex justify-center items-center' to={link}>
                {Logo}
                <AppText text={text} className={textStyle + ' ' + `${type !== 'navbar' ? "mobile:hidden " : ""}`} />
            </Link>
        </div>
    )
}
