import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import { routes } from './routes'
import { Sidebar, Navbar } from "../containers/index"
import { UserTypes } from '../_redux/types/user'
import { useEffect } from 'react'
import { GetCurrentUserInfos } from '../api/dashboardApi'
import { UserAction } from '../_redux/actions/user'
import { useDispatch } from 'react-redux'


const ApplicationRoutes = (): JSX.Element => {
    const dispatch = useDispatch()
    useEffect(() => {
        GetCurrentUserInfos().then(res => {
            const payload = res?.data
            if (res?.status === 200) {
                UserAction(payload)(UserTypes.GET_USER_SUCCESS)(dispatch)
            } else {
                UserAction(payload)(UserTypes.GET_USER_FAILURE)(dispatch)
            }
        }).catch(err => {
            const payload = err?.response?.data?.message
            UserAction(payload)(UserTypes.GET_USER_FAILURE)(dispatch)
        })
    }, [])
    return (
        <div className='w-full flex'>
            <Sidebar />
            <div className='w-full bg-white relative'>
                <Navbar />
                <div className='p-8 laptop:p-6 tablet:p-4 mobile:py-2 mobile:px-3'>
                    <Switch>
                        {routes.map((route) => {
                            return (
                                <Route
                                    key={`route_${route.path}`}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props: RouteComponentProps) => (
                                        <route.component
                                            name={route.name}
                                            {...props}
                                            {...route.props}
                                        />
                                    )}
                                />
                            );
                        })}
                    </Switch>
                </div>
            </div>
        </div>
    )
}
export default ApplicationRoutes