import api, { baseURL } from '.';
import { IInsuranceModel } from '../_typescript/IInsurancesModel';
export const getInsuranceTypes = async () => {
  return await api
    .get(`${baseURL}/api/v0/types`)
    .then(({ data }) => data)
    .catch((error) => {
      return error.response;
    });

};
export const getInsuranceProviders = async () => {
  return await api
    .get(`${baseURL}/api/v0/providers`)
    .then(({ data }) => data)
    .catch((error) => {
      return error.response;
    });
};
export const getTypesByProvider = async (id: number, companyType: boolean) => {
  return await api
    .get(`${baseURL}/api/v0/type-by-provider/${id}?companyType=${companyType}`)
    .then(({ data }) => data)
    .catch((error) => {
      return error.response;
    });
};
export const getInsurancesList = async (pageToShow: number) => {
  return await api
    .get(`${baseURL}/api/v0/my-paginated-insurances?page=${pageToShow}`)
    .then(({ data }) => data)
    .catch((error) => {
      return error.response;
    });
};
export const addInsurances = async (insurance: IInsuranceModel) => {
  return await api
    .post(`${baseURL}/api/v0/insurances`, insurance)
    .then(({ data }) => data)
    .catch((error) => {
      return error.response;
    });
};
export const getInsurancesById = async (id: string) => {
  return await api
    .get(`${baseURL}/api/v0/insurances/${id}`)
    .then((res) => res)
    .catch((error) => {
      return error.response;
    });
}; export default { getInsuranceTypes, getInsuranceProviders, addInsurances, getInsurancesById };
